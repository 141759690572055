import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { TypeManagerDecorator } from "../../main/type.map.service";

const DEFAULT_SVG_PATH = "./resources/typescript/assets/css-compiled/images/sprite.svg#";
const DEFAULT_SVG_NAME = "caret--caret-down";
@TypeManagerDecorator("s25-simple-collapse")
@Component({
    selector: "s25-simple-collapse",
    template: `
        @if (headerText) {
            <div class="simple-collapse--wrapper c-margin-top--single" [class.expanded]="expanded">
                <div class="c-sectionHead">
                    <h2>{{ headerText }}</h2>
                </div>
                <ng-container *ngTemplateOutlet="collapse"></ng-container>
            </div>
        }
        @if (!headerText) {
            <ng-container *ngTemplateOutlet="collapse"></ng-container>
        }

        <ng-template #collapse>
            <div class="c-objectDetails--collapseLabel">
                <div
                    class="toggleButton"
                    (click)="toggle()"
                    (keydown.enter)="toggle()"
                    attr.aria-label="{{ titleText }}"
                    attr.aria-expanded="{{ expanded }}"
                    tabindex="0"
                    [id]="id"
                    role="button"
                >
                    <svg
                        [ngClass]="{ 'c-svgIcon': true }"
                        role="presentation"
                        style="transition: all .35s; transform: {{ direction }};"
                    >
                        <title>{{ titleText }}</title>
                        <use xmlns:xlink="http://www.w3.org/1999/xlink" attr.xlink:href="{{ svgLocation }}" />
                    </svg>
                </div>
                @if (hasClose) {
                    <div class="closeButton" role="button" (click)="onClose()" (keydown.enter)="onClose()" tabindex="0">
                        <s25-ng-icon [type]="'close'"></s25-ng-icon>
                    </div>
                }
            </div>

            <div [ngClass]="{ 'content-wrapper': true, 'content-wrapper--hidden': !expanded }" [hidden]="!expanded">
                <ng-content></ng-content>
            </div>
        </ng-template>
    `,
    styles: `
        s25-simple-collapse .c-objectDetails--collapseLabel {
            display: flex !important;
        }

        s25-simple-collapse .toggleButton {
            flex-grow: 1;
            position: relative;
        }

        s25-simple-collapse .closeButton {
            aspect-ratio: 1;
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class S25SimpleCollapseComponent implements OnInit {
    @Input() svgName?: String = DEFAULT_SVG_NAME;
    @Input() headerText?: string;
    @Input() titleText?: String = "Collapse or Open";
    @Input() expanded?: Boolean = true;
    @Input() defaultCollapsed?: Boolean = false;
    @Input() hasClose?: boolean = false;

    @Output() closed = new EventEmitter<void>();

    static count: number = 0;
    id: string;
    init: Boolean = false;
    svgLocation: String;
    closeSvgLocation: string;
    direction: String = "rotate(180deg)";

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        S25SimpleCollapseComponent.count++;
        this.id = "ngSimpleCollapse-" + S25SimpleCollapseComponent.count;
    }

    ngOnInit(): void {
        this.elementRef.nativeElement.angBridge = this;
        this.svgLocation = DEFAULT_SVG_PATH + this.svgName;
        this.closeSvgLocation = DEFAULT_SVG_PATH + "close-x";
        this.defaultCollapsed && this.toggle();
        this.init = true;
        this.cd.detectChanges();
    }

    toggle() {
        this.expanded = !this.expanded;
        this.direction = this.expanded ? "rotate(180deg)" : "rotate(0deg)";
        this.cd.detectChanges();
    }

    onClose() {
        this.closed.emit();
    }
}
