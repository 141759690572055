import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    NgZone,
    Renderer2,
    ViewChild,
    signal,
    computed,
} from "@angular/core";
import { UserprefService } from "../../services/userpref.service";
import { S25Util } from "../../util/s25-util";
import { S25Datefilter } from "../s25-dateformat/s25.datefilter.service";

@Component({
    selector: "s25-ng-slider",
    template: `@if (init) {
        <div class="slider-wrapper" [class.has-down-labels]="ranges().length">
            <div #slider class="rail" [style.background]="linearGradient" (mouseup)="onMouseUp()">
                @for (range of ranges(); track range; let i = $index) {
                    <div>
                        <div
                            class="thumb"
                            role="slider"
                            [attr.aria-label]="range.startLabel"
                            [attr.aria-valuenow]="range.start"
                            [attr.aria-valuemin]="minValue()"
                            [attr.aria-valuemax]="maxValue()"
                            [style.left.%]="range.position"
                            [class.read-only]="readonly"
                            [class.down-label]="i % 2 !== 0"
                            (mousedown)="onMouseDown($event, range, i, 'start')"
                            (keydown)="setValues(range, 'start', null, i, $event)"
                        >
                            <div class="slider-label" [class.down-label]="i % 2 !== 0">
                                <p class="label-text">{{ range.startLabel }}</p>
                                <p>{{ range.startFormat || range.start }}</p>
                            </div>
                            <div class="thumb-anchor" tabindex="0"></div>
                        </div>
                        @if (range.end) {
                            <div
                                class="thumb"
                                role="slider"
                                [attr.aria-label]="range.endLabel || 'End'"
                                [attr.aria-valuenow]="range.end"
                                [attr.aria-valuemin]="minValue()"
                                [attr.aria-valuemax]="maxValue()"
                                [style.left.%]="range.position2"
                                [class.read-only]="readonly"
                                [class.down-label]="i % 2 !== 0"
                                (mousedown)="onMouseDown($event, range, i, 'end')"
                                (keydown)="setValues(range, 'end', null, i, $event)"
                            >
                                <div class="slider-label" [class.down-label]="i % 2 !== 0">
                                    <p class="label-text">{{ range.endLabel }}</p>
                                    <p>{{ range.endFormat || range.end }}</p>
                                </div>
                                <div class="thumb-anchor" tabindex="0"></div>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    }`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25SliderComponent implements OnInit {
    @Input() model: SliderModel;
    @Input() readonly: boolean = true;

    @Output() onChange: EventEmitter<SliderModel> = new EventEmitter<SliderModel>();

    @ViewChild("slider") slider: ElementRef;

    init: boolean;
    ranges = signal<Range[]>([]);
    isDragging: boolean;
    sliderRange = computed<number>(() => this.maxValue() - this.minValue());
    currentRange: Range | null;
    currentThumb: Thumb | null;
    currentRangeIndex: number;
    linearGradient: string;
    dateFormat: string;
    timeFormat: string;
    is24Hour: boolean;
    activeLabel: HTMLDivElement | null;
    iniModel: SliderModel;
    minValue = signal<number>(0);
    maxValue = signal<number>(0);
    step: number = 0.25;
    numDays: number = 0;

    constructor(
        private cd: ChangeDetectorRef,
        private zone: NgZone,
        private renderer: Renderer2,
    ) {}

    @HostListener("mousedown", ["$event"])
    onMouseDown(event: MouseEvent, range: Range, index: number, thumb: Thumb) {
        if (thumb !== undefined) {
            if (!this.readonly) {
                this.isDragging = true;
                this.currentRange = range;
                this.currentRangeIndex = index;
                this.currentThumb = thumb;
            }
            this.setActiveLabel(event);
        }

        !this.readonly && this.setInitialThumbPosition(event.clientX, this.currentRange, this.currentThumb);
    }

    @HostListener("mousemove", ["$event"])
    onMouseMove(event: MouseEvent) {
        if (this.isDragging && !this.readonly)
            this.handleMouseMove(event, this.currentRange, this.currentRangeIndex, this.currentThumb);
    }

    @HostListener("mouseup", ["$event"])
    onMouseUp() {
        if (!this.readonly) {
            this.isDragging = false;
            this.currentRange = null;
            this.currentThumb = null;

            //// convert ranges back to date
            this.iniModel.ranges.forEach((range: Range, index: number) => {
                // Update the start property of the range in initMode
                range.start = new Date(range.start).setHours(this.model.ranges[index].start as number);
                range.start = new Date(range.start).setMinutes(((this.model.ranges[index].start as number) % 1) * 60);
                range.start = new Date(range.start);

                range.end = new Date(range.end).setHours(this.model.ranges[index].end as number);
                range.end = new Date(range.end).setMinutes(((this.model.ranges[index].end as number) % 1) * 60);
                range.end = new Date(range.end);
            });

            this.onChange.emit(this.iniModel);
        }
    }

    async ngOnInit() {
        try {
            if (this.model.type === "time") {
                this.dateFormat = await UserprefService.getS25DateTimeformat();
                this.timeFormat = await UserprefService.getS25Timeformat();
                this.is24Hour = await UserprefService.getIs24HourTime();
            }
        } catch (e) {
            console.error(e);
        }

        this.iniModel = S25Util.deepCopy(this.model);

        this.numDays = S25Util.date.diffDays(this.model.ranges.at(-1).start, this.model.ranges.at(-1).end);

        this.maxValue.set(this.convertToDecimal(this.model.ranges.at(-1).end as Date) + this.model.step);
        this.minValue.set(this.convertToDecimal(this.model.ranges.at(-1).start as Date) - this.model.step);

        this.ranges.set(
            this.model.ranges.map((range: Range, index) => {
                range.start = this.convertToDecimal(range.start as Date);
                if (range.end) range.end = this.convertToDecimal(range.end as Date);

                range.position = this.getPosition(range.start as number);
                if (range.end) {
                    range.position2 = this.getPosition(range.end as number);
                }
                range.startFormat = this.setFormat(this.iniModel.ranges[index].start as Date);

                range.endFormat = range.end && this.setFormat(this.iniModel.ranges[index].end as Date);
                return range;
            }),
        );

        this.getGradient();

        this.init = true;

        this.cd.detectChanges();
    }

    handleMouseMove(event: MouseEvent, range: Range, index: number, thumb: Thumb) {
        if (!range) return;

        this.zone.run(() => {
            const rect = this.slider.nativeElement.getBoundingClientRect();
            const x = event.clientX - rect.left;
            const percent = x / rect.width;

            const newValue = this.minValue() + percent * this.sliderRange();

            const roundedValue = Math.round(newValue / this.step) * this.step;

            const value = Math.max(this.minValue(), Math.min(this.maxValue(), roundedValue));

            this.setValues(range, thumb, value, index);
        });
    }

    setValues(range: Range, thumb: Thumb, value: number, index: number, event?: KeyboardEvent) {
        const step = this.step;
        const allRanges = this.model.ranges;
        const positionProp = thumb === "start" ? "position" : "position2";
        const formatProp = thumb === "start" ? "startFormat" : "endFormat";

        if (event) {
            event.key !== "Tab" && event.preventDefault();

            this.setActiveLabel(event);

            const keyMod =
                event.key === "ArrowRight" || event.key === "ArrowUp"
                    ? step
                    : event.key === "ArrowLeft" || event.key === "ArrowDown"
                      ? -step
                      : 0;

            value = (range[thumb] as number) + keyMod;
        }

        const position = this.getPosition(value);
        let maxValue: number, minValue: number, maxPosition: number, minPosition: number;

        if (thumb === "start") {
            minValue = (allRanges[index + 1]?.start ?? this.minValue() - step) as number;
            minPosition = allRanges[index + 1]?.position;

            if (index === 0) {
                maxValue = (range.end ?? allRanges[index - 1]?.start ?? this.maxValue() + step) as number;
                maxPosition = range.position2;
            } else {
                maxValue = allRanges[index - 1].start as number;
                maxPosition = allRanges[index - 1].position;
            }
        } else if (thumb === "end") {
            maxValue = (allRanges[index + 1]?.end ?? this.maxValue() + step) as number;
            minValue = (index === 0 ? range.start : allRanges[index - 1].end) as number;
            maxPosition = allRanges[index + 1]?.position2;
            minPosition = index === 0 ? range.position : allRanges[index - 1].position2;
        }

        range[thumb] = value >= maxValue ? maxValue - step : value <= minValue ? minValue + step : value;

        range[positionProp] =
            position >= maxPosition
                ? this.getPosition(maxValue, -step)
                : position <= minPosition
                  ? this.getPosition(minValue, step)
                  : position;

        // range[formatProp] = this.setFormat(range[thumb] as number);

        this.getGradient();

        this.cd.detectChanges();
    }

    setActiveLabel(event: any) {
        if (this.activeLabel) this.renderer.removeStyle(this.activeLabel, "z-index");

        this.activeLabel =
            event.type === "keydown" || event.target.nodeName === "DIV"
                ? event.target.offsetParent
                : event.target.offsetParent.offsetParent;

        this.renderer.setStyle(this.activeLabel, "z-index", 1);
    }

    getPosition(value: number, step: number = 0) {
        return ((value + step - this.minValue()) / this.sliderRange()) * 100;
    }

    setInitialThumbPosition(mouseX: number, range: Range, thumb: Thumb) {
        const rect = this.slider.nativeElement.getBoundingClientRect();
        const x = mouseX - rect.left;
        const percent = x / rect.width;

        const position = percent * 100;

        if (thumb === "start") {
            range.position = position;
        } else if (thumb === "end") {
            range.position2 = position;
        }
    }

    getGradient() {
        if (!this.model.ranges[0].end) {
            this.linearGradient = `linear-gradient(to right, green ${this.model.ranges[0].position}%, #fff ${this.model.ranges[0].position}%)`;
        } else {
            const colorMap: { [key: number]: string } = {
                0: "#080",
                1: "#5cbf5c",
                2: "#D2F4F6",
            };

            let rangeStart = ["linear-gradient(to right, #fff 0%,"];
            let rangeEnd = ["#fff 100%)"];
            const lastIndex = this.model.ranges.length - 1;

            for (let i = lastIndex; i >= 0; i--) {
                const range = this.model.ranges[i];

                rangeStart.push(
                    ` ${i === lastIndex ? "#fff" : colorMap[i + 1]} ${range.position}%, ${colorMap[i]} ${
                        range.position
                    }%,`,
                );

                rangeEnd.unshift(
                    ` ${colorMap[i]} ${range.position2}%, ${i === lastIndex ? "#fff" : colorMap[i + 1]} ${
                        range.position2
                    }%, `,
                );
            }

            this.linearGradient = [...rangeStart, ...rangeEnd].join("");
        }
    }

    setFormat(date: Date) {
        if (this.model.type === "time") {
            return this.numDays > 0
                ? S25Datefilter.transform(date, this.dateFormat)
                : S25Datefilter.transform(date, this.timeFormat);

            // const baseDate = this.iniModel.ranges.at(-1).start;
            // console.log(S25Util.date.diffDays(baseDate, date), "diffDays");
            // const diffHours = S25Util.date.diffHours(baseDate, date);
            // console.log(diffHours, "diffHours");
            // const modifier = diffHours / 24;
            // console.log(modifier, "modifier");
            // const subtract = modifier * diffHours;
            // console.log(subtract, "subtract");
            // const timeString = time.toString().split(".");
            // const numHour = parseInt(timeString[0]);
            // const hour = !this.is24Hour && numHour > 12 ? numHour - 12 : timeString[0];
            //
            // let minutes = Math.round(+((+timeString[1] || 0) * 60).toString().slice(0, 4) / 100).toString();
            //
            // let suffix = "";
            // if (!this.is24Hour) {
            //     if (numHour === 0 || numHour === 24 || numHour < 12) {
            //         suffix = "AM";
            //     } else if (numHour >= 12) {
            //         suffix = "PM";
            //     }
            // }
            //
            // return `${!this.is24Hour && hour == 0 ? 12 : hour}:${minutes.length < 2 ? minutes.padEnd(2, "0") : minutes}${suffix}`;
        }
    }

    convertToDecimal(value: Date) {
        if (this.model.type === "time") {
            const diffDays = this.getDiffDays(value);
            const splitNum: Array<string | number> = S25Util.date.toTimeStr(value, true).split(":");
            splitNum[1] = parseInt(splitNum[1] as string) / 60;
            return parseInt(splitNum[0] as string) + diffDays + splitNum[1];
        }
    }

    getDiffDays(date: Date) {
        const baseDate = this.iniModel.ranges.at(-1).start;
        return Math.floor(S25Util.date.diffDays(baseDate, date) * 24);
    }
}

export type SliderModel = {
    type: "time" | "date" | "standard";
    // minValue: number;
    // maxValue: number;
    step: number;
    ranges: Range[]; // for multiple ranges, range precedent is determined by index - the first index is the central range, and successive indices (ie setup times, etc) encompass previous ranges
};

export type Range = {
    start: number | Date;
    end?: number | Date | null;
    startLabel: string;
    endLabel?: string | null;
    position?: number;
    position2?: number;
    startFormat?: string;
    endFormat?: string;
};

export type Thumb = "start" | "end";
