import { SettingsService } from "../../services/settings.service";
import { S25Util } from "../../util/s25-util";
import { PreferenceService } from "../../services/preference.service";
import {
    ChangeDetectorRef,
    Component,
    OnInit,
    ElementRef,
    ChangeDetectionStrategy,
    ViewEncapsulation,
} from "@angular/core";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { StandoutClassroomService } from "../../services/standout.classroom.service";
import { Bind } from "../../decorators/bind.decorator";

@TypeManagerDecorator("s25-ng-service-portal")
@Component({
    selector: "s25-ng-service-portal",
    template: `
        @if (init) {
            <div class="c-margin-bottom--double service-portal">
                <div>
                    <h2 class="c-margin-bottom--single">Service Portal</h2>
                    <p class="ngBold c-margin-bottom--quarter c-margin-top--single">Create a Lynx User</p>
                    <s25-ng-lynx-users></s25-ng-lynx-users>

                    <!--Freshbooks -->
                    <s25-ng-freshbooks-config></s25-ng-freshbooks-config>

                    <p class="ngBold c-margin-bottom--quarter c-margin-top--single">Cache Management</p>
                    <s25-spring-cache></s25-spring-cache>

                    <p class="ngBold c-margin-bottom--quarter c-margin-top--single">Set X25 Access</p>
                    <s25-ng-checkbox
                        [(modelValue)]="x25Enabled"
                        (modelValueChange)="setX25Access()"
                        aria-label="Enable Full X25 Access"
                        >Enable Full X25 Analytics Access</s25-ng-checkbox
                    >

                    <p class="ngBold c-margin-bottom--quarter c-margin-top--single">Set Optimizer Access</p>
                    <s25-ng-checkbox
                        [(modelValue)]="optimizerEnabled"
                        (modelValueChange)="setOptimizerAccess()"
                        aria-label="Enable Optimizer Access"
                        >Enable Optimizer Access</s25-ng-checkbox
                    >

                    <div class="ngBold c-margin-bottom--quarter c-margin-top--single">Eval25</div>
                    <s25-ng-checkbox [(modelValue)]="eval25Enabled" (modelValueChange)="saveEval25()"
                        >Eval25 Location Satisfaction</s25-ng-checkbox
                    >

                    <p class="ngBold c-margin-top--single">Set Beta Access</p>
                    <p class="ngFinePrint c-margin-bottom--quarter">No active beta features</p>

                    <s25-ng-preference-toggle
                        class="wrapper c-displayInlineBlock"
                        prefName="HasBetaFeatures"
                        prefType="S"
                        [label]="'Full Beta Feature Access'"
                    ></s25-ng-preference-toggle>

                    <div class="ngBold c-margin-bottom--quarter c-margin-top--single">JReport Server Settings</div>
                    <label for="jrserverInst" class="c-margin-bottom--quarter">JR Report Server Instance Name</label
                    ><br />
                    <input name="jrserverInst" [(ngModel)]="jrserverInst" type="text" class="c-input" /><br />
                    <label for="jrserverUrl" class="c-margin-bottom--quarter">JR Report Server URL</label><br />
                    <input
                        name="jrserverUrl"
                        [(ngModel)]="jrserverUrl"
                        type="text"
                        class="c-input ng-pristine ng-valid ng-scope ng-empty ng-valid-maxlength ng-touched"
                    /><br />
                    <button
                        class="aw-button aw-button--primary c-margin-top--single c-margin-bottom--quarter"
                        (click)="saveReportSettings()"
                    >
                        Save Report Settings
                    </button>

                    <div class="ngBold c-margin-bottom--quarter c-margin-top--single">StandOut Classroom</div>

                    <label class="c-margin-bottom--quarter"
                        >Standout Classroom URL:
                        <s25-ng-editable-text
                            [id]="'standOutUrl'"
                            [allowEmpty]="true"
                            [hasCommit]="true"
                            [(val)]="standOutUrl"
                            [onCommit]="saveStandOutUrl"
                        ></s25-ng-editable-text>
                    </label>

                    <div class="c-margin-bottom--quarter c-margin-top--single">
                        <s25-ng-errors></s25-ng-errors>
                    </div>
                </div>
            </div>
        }
    `,
    styles: [
        `
            .service-portal {
                input[type="text"] {
                    width: 60%;
                    max-width: 400px;
                }
                s25-ng-editable-text {
                    width: 100%;
                    input {
                        min-width: 400px;
                    }
                }
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.Emulated,
})
export class S25ServicePortalComponent implements OnInit {
    // PREFS = ["HasBetaFeatures", "config_jrserver_inst", "config_jrserver_url", "standout_classroom"];
    init = false;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {}

    async ngOnInit() {
        this.elementRef.nativeElement.angBridge = this;

        SettingsService.getSettings().then((settings) => {
            this.x25Enabled = S25Util.toBool(settings.CAN_USE_X25);
            this.eval25Enabled = S25Util.toBool(settings.CAN_USE_EVAL25);
            this.optimizerEnabled = S25Util.toBool(settings.CAN_USE_OPTIMIZER);
        });

        this.getReportSettings();
        await this.getStandOutUrl();
        this.init = true;
        this.cd.detectChanges();
    }

    eval25Enabled: boolean;
    saveEval25() {
        return SettingsService.setSetting("CAN_USE_EVAL25", this.eval25Enabled ? 1 : 0);
    }

    //x25
    x25Enabled: boolean;
    setX25Access() {
        //https://collegenet.atlassian.net/wiki/spaces/X25/pages/21659721/Licenses+and+Functionality
        return SettingsService.setSetting("CAN_USE_X25", this.x25Enabled ? 1 : 0);
    }

    //Optimizer
    optimizerEnabled: boolean;
    async setOptimizerAccess() {
        return SettingsService.setSetting("CAN_USE_OPTIMIZER", this.optimizerEnabled ? 1 : 0);
    }

    //Reports
    jrserverInst: string;
    jrserverUrl: string;
    getReportSettings() {
        PreferenceService.getPreferences(["config_jrserver_inst", "config_jrserver_url"], "S").then((prefs) => {
            this.jrserverInst = prefs["config_jrserver_inst"].value;
            this.jrserverUrl = prefs["config_jrserver_url"].value;
        });
    }

    async saveReportSettings() {
        await S25Util.all({
            inst: PreferenceService.setPreference("config_jrserver_inst", this.jrserverInst, "S"),
            url: PreferenceService.setPreference("config_jrserver_url", this.jrserverUrl, "S"),
        });
        this.getReportSettings();
    }

    //standout - integration is Currently turned completely off 10/7/2024
    standOutUrl: string;
    async getStandOutUrl() {
        this.standOutUrl = await StandoutClassroomService.getUrl();

        const resp = await PreferenceService.getPreferences(["standout_classroom"], "S");
        this.standOutUrl = resp["standout_classroom"]?.value || "";
        return this.standOutUrl;
    }

    @Bind
    async saveStandOutUrl() {
        await PreferenceService.setPreference("standout_classroom", this.standOutUrl || "", "S");
    }
}
